:root {
  --dark-background: #171717;
  --gray: #fffc;
  --gray-background: #c8c8dc1a;
  --gray-dark-background: #161a20;
  --blue: #1e46a0;
  --light-blue: #75bfff;
  --light-gray: #f2f2f2;
  --orange: #e65032;
  --success: #4caf50;
  --failure: #ff5722;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

@media (prefers-color-scheme: light) {
  body {
    color: #000;
    background-color: #fff;
  }

  h1, h2, h3 {
    color: var(--blue);
  }

  th {
    background-color: var(--light-gray);
  }
}

@media (prefers-color-scheme: dark) {
  body {
    color: #fff;
    background-color: var(--dark-background);
  }

  h1, h2, h3 {
    color: var(--light-blue);
  }

  th {
    background-color: var(--gray-background);
  }
}

main {
  flex-direction: column;
  align-items: center;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
}

body, input, textarea {
  text-align: center;
  font: 18px / 1.25 Noto Sans, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
}

.hidden {
  display: none !important;
}

img {
  object-fit: fill;
  border-radius: 16px;
  width: 100%;
  height: 100%;
}

.věruška {
  max-width: 400px;
}

.meals-container {
  flex-flow: wrap;
  justify-content: center;
  gap: 50px;
  display: flex;
}

table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px;
}

th, td {
  text-align: left;
  border: 1px solid #ddd;
  padding: 8px;
}

.meal {
  border: 2px solid #ccc;
  border-radius: 5px;
  flex-grow: 1;
  flex-basis: 1px;
  padding: 10px;
}

.meal-current-rating-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  display: flex;
}

.meal-rating-header {
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  display: flex;
}

.meal-rating-form {
  vertical-align: middle;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.leaderboard-table td:last-child:after, .star-suffix:after {
  content: "";
  color: var(--yellow);
  margin: 5px;
  font-family: FontAwesome;
  font-size: 1.25em;
  display: inline-block;
}

.meal[finished] {
  background-color: var(--success);
}

.meal[finished] .rating {
  pointer-events: none;
}
/*# sourceMappingURL=index.926a219a.css.map */
